import React from "react";

export const Footer = () => {
  return (
    <div className="footer">
      <p>
        yapan{" "}
        <a href="https://emre.ca" target="_blank" rel="noreferrer">
          f5uck
        </a>
      </p>
      <p>
        oyuna katki yapmak icin{" "}
        <a href="https://github.com/MostlyEmre/replikworks" target="_blank" rel="noreferrer">
          github linki
        </a>
      </p>
    </div>
  );
};
